import { WebSocketTaskDefinition } from "@/main/webapp/vue/model/api/web-socket/WebSocketTaskDefinition";
import { WebSocketTaskType } from "@/main/webapp/vue/model/api/web-socket/WebSocketTaskType";

export class WebSocketTaskDefinitions {

  static generateDefinitionWithTypeString(typeString: String): WebSocketTaskDefinition {
    return this.generateDefinitionWithType(typeString as unknown as WebSocketTaskType);
  }

  static generateDefinitionWithType(type: WebSocketTaskType): WebSocketTaskDefinition {
    switch (type) {
      case WebSocketTaskType.CONTENT_PACKAGE :
        return new WebSocketTaskDefinition(type, "/content-package", "/content-package/cancel", "zip", 15000, 200);
      case WebSocketTaskType.CONTENT_REPORT :
        return new WebSocketTaskDefinition(type, "/content-report", "/content-report/cancel", "pptx", 15000, 250);
      case WebSocketTaskType.RAW_DATA :
        return new WebSocketTaskDefinition(type, "/raw-data-report", "/raw-data-report/cancel", "xlsx", 20000, 50000);
      case WebSocketTaskType.CHECK_IN :
        return new WebSocketTaskDefinition(type, "/check-in-report", "/check-in-report/cancel", "xlsx", 5000);
      case WebSocketTaskType.PROJECT_LIST :
        return new WebSocketTaskDefinition(type, "/project-list-report", "/project-list-report/cancel", "xlsx", 5000);
      case WebSocketTaskType.PROJECT_STATUS :
        return new WebSocketTaskDefinition(type, "/project-status-report", "/project-status-report/cancel", "xlsx", 5000);
      case WebSocketTaskType.PROJECT_USER_PERFORMANCE :
        return new WebSocketTaskDefinition(type, "/project-user-performance-report", "/project-user-performance-report/cancel", "xlsx", 5000);
      case WebSocketTaskType.FRAUD_DATA :
        return new WebSocketTaskDefinition(type, "/fraud-data-report", "/fraud-data-report/cancel", "xlsx", 5000, 50000);
      case WebSocketTaskType.SHOP_EXPORT :
        return new WebSocketTaskDefinition(type, "/shop-export", "/shop-export/cancel", "xlsx", 5000);
      case WebSocketTaskType.SHOP_IMPORT :
        return new WebSocketTaskDefinition(type, "/shop-import", "/shop-import/cancel", "csv", 5000);
      case WebSocketTaskType.DELETE_INACTIVE_SHOPS :
        return new WebSocketTaskDefinition(type, "/delete-inactive-shops", "", "", 10000);
    }

    throw new Error("Task type not registered");
  }

  static types(): WebSocketTaskType[] {
    return Object.values(WebSocketTaskType);
  }

}
